import {
  CareerAnimatorIcon,
  CareerCreativeHeadIcon,
  CareerDevIcon,
  CareerGraphicDesignerIcon,
  CareerLinkedInIcon,
  CareerWordpressIcon,
  SmallCareerAnimatorIcon,
  SmallCareerCreativeHeadIcon,
  SmallCareerDevIcon,
  SmallCareerGraphicDesignerIcon,
  SmallCareerLinkedInIcon,
  SmallCareerWordpressIcon,
} from "./svgs";

const jobs = [
  {
    title: "Sr. Full Stack Developer",
    field: "(Python + React)",
    icon: CareerDevIcon,
    smallIcon: SmallCareerDevIcon,
    location: "Lahore, Pak",
    timing: "Full Time",
    tech: "Engineering",
    positions: "02",
  },

  {
    title: "Full Stack Developer",
    field: "(Python + React)",
    icon: CareerDevIcon,
    smallIcon: SmallCareerDevIcon,
    location: "Lahore, Pak",
    timing: "Full Time",
    tech: "Engineering",
    positions: "03",
  },
  {
    title: "Sr. WordPress Developer",
    icon: CareerWordpressIcon,
    smallIcon: SmallCareerWordpressIcon,
    location: "Lahore, Pak",
    timing: "Full Time",
    tech: "Engineering",
    positions: "02",
  },
  {
    title: "Graphic Designer",
    icon: CareerGraphicDesignerIcon,
    smallIcon: SmallCareerGraphicDesignerIcon,
    location: "Lahore, Pak",
    timing: "Full Time",
    tech: "Engineering",
    positions: "02",
  },
  {
    title: "Animator",
    icon: CareerAnimatorIcon,
    smallIcon: SmallCareerAnimatorIcon,
    location: "Lahore, Pak",
    timing: "Full Time",
    tech: "Animation",
    positions: "02",
  },
  {
    title: "Creative Head",
    icon: CareerCreativeHeadIcon,
    smallIcon: SmallCareerCreativeHeadIcon,
    location: "Lahore, Pak",
    timing: "Full Time",
    tech: "Engineering",
    positions: "02",
  },
  {
    title: "LinkedIn Sales Navigator - BD",
    icon: CareerLinkedInIcon,
    smallIcon: SmallCareerLinkedInIcon,
    location: "Lahore, Pak",
    timing: "Full Time",
    tech: "Engineering",
    positions: "02",
  },
];
export default jobs;
